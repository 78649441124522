import * as ActionTypes from './constants';
import ClanService from './service';

/**
 * --------------------
 * Fetch clan info.
 * --------------------
 */
export const fetchClanInfo = (clanTag) => {
  // clan tag without #
  return async (dispatch) => {
    dispatch(fetchingClanInfoRequest());
    try {
      const response = await ClanService.getClanInfo(clanTag);
      if (response.status === 200) {
        dispatch(fetchingClanInfoSuccess(response.data));
      }
    } catch (e) {
      dispatch(fetchClanTagFailure());
    }
  };
};

const fetchingClanInfoRequest = () => ({
  type: ActionTypes.FETCHING_CLAN_INFO_REQUEST,
});

const fetchClanTagFailure = () => ({
  type: ActionTypes.FETCHING_CLAN_INFO_FAILURE,
});

const fetchingClanInfoSuccess = (payload) => ({
  type: ActionTypes.FETCHING_CLAN_INFO_SUCCESS,
  payload,
});
