import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import ClanLogo from '../../components/logo';
import { Favorite } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';
import { clanNameSelector } from '../../store/clan/selectors';

const FooterContainer = ({ title }) => {
  const classes = useStyles();
  return (
    <footer className={classes.root}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        px={4}
      >
        {/* <Box textAlign="center" mb={3}>
          <ClanLogo classes={classes.logo} />
          <Typography className={clsx(classes.textLogo)} component="h3" noWrap>
            {title}
          </Typography>
        </Box> */}
        <Box mb={3} textAlign="center">
          <Typography className={clsx(classes.textLogo)} component="h3" noWrap>
            2021
          </Typography>
          <Typography className={clsx(classes.textLogo)} component="h3" noWrap>
            Powered By API Supercell
          </Typography>
        </Box>

        <Box mb={1} className={classes.boxBuildWith}>
          <Typography className={classes.textBuildWith} component="p">
            <Typography variant="body2" component="span">
              Made with
            </Typography>
            <Favorite />
            <Typography variant="body2" component="span">
              By{' '}
              <a target="_blank" href="https://riski.me" rel="noreferrer">
                Riski
              </a>
            </Typography>
          </Typography>
          <Box textAlign="center">
            <Typography variant="body2" component="p">
              Bandung, Indonesia
            </Typography>
          </Box>
        </Box>
      </Box>
    </footer>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(8, 0, 4, 0),
  },
  logo: {
    width: 52,
  },
  textLogo: {
    fontSize: 15,
    fontWeight: theme.typography.fontWeightBold,
  },
  boxBuildWith: {
    '& svg': {
      color: 'red',
      fontSize: 18,
      margin: '0 6px',
    },
  },
  textBuildWith: {
    display: 'flex',
    alignItems: 'center',
  },
}));

FooterContainer.propTypes = {
  title: PropTypes.string.isRequired,
};

FooterContainer.defaultProps = {
  title: 'Crunch Squad',
};

const mapStateToProps = (state) => ({
  title: clanNameSelector(state),
});

export default connect(mapStateToProps)(FooterContainer);
