import React from 'react';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import ClanLogo from '../logo';

const PageLoader = () => {
  const classes = useStyles();
  return (
    <Box
      width="100%"
      height="100vh"
      display="flex"
      position="fixed"
      textAlign="center"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      className={classes.root}
    >
      <ClanLogo classes={classes.logo} />
      <Box mt={2}>
        <CircularProgress size={34} color="primary" />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.default,
    backgroundColor: '#d1e5f8',
  },
  logo: {
    width: 125,
    height: 125,
  },
}));

export default PageLoader;
