import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Grid, Typography, Box } from '@material-ui/core';
import CardMemberItem from '../card-member-item';
import {
  clanCoLeadersSelector,
  clanLeadersSelector,
  clanAllMembersSelector,
  clanSeniorsSelector,
  clanMembersSelector,
} from '../../store/clan/selectors';

const MemberListContainer = ({
  allMembers,
  leaders,
  coLeaders,
  seniors,
  members,
}) => {
  return (
    <Container>
      {Array.isArray(allMembers) && allMembers.length > 0 ? (
        <Fragment>
          {/* Leaders and Co-Leaders */}
          <Box mb={2} mt={4}>
            <Typography component="h2" variant="h4">
              Leaderboard
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {leaders.map((item) => (
              <Grid key={item.tag} item xs={6} md={4} lg={3}>
                <CardMemberItem player={item} />
              </Grid>
            ))}
            {coLeaders.map((item) => (
              <Grid key={item.tag} item xs={6} md={4} lg={3}>
                <CardMemberItem player={item} />
              </Grid>
            ))}
          </Grid>

          {/* Seniors */}
          <Box mb={2} mt={6}>
            <Typography component="h2" variant="h4">
              Seniors Member
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {seniors.map((item) => (
              <Grid key={item.tag} item xs={6} md={4} lg={3}>
                <CardMemberItem player={item} />
              </Grid>
            ))}
          </Grid>
          <Box mb={2} mt={6}>
            <Typography component="h2" variant="h4">
              Members
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {members.map((item) => (
              <Grid key={item.tag} item xs={6} md={4} lg={3}>
                <CardMemberItem player={item} />
              </Grid>
            ))}
          </Grid>
        </Fragment>
      ) : null}
    </Container>
  );
};

MemberListContainer.propTypes = {
  allMembers: PropTypes.array.isRequired,
  leaders: PropTypes.array.isRequired,
  coLeaders: PropTypes.array.isRequired,
  seniors: PropTypes.array.isRequired,
  members: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  allMembers: clanAllMembersSelector(state),
  leaders: clanLeadersSelector(state),
  coLeaders: clanCoLeadersSelector(state),
  seniors: clanSeniorsSelector(state),
  members: clanMembersSelector(state),
});

export default connect(mapStateToProps, null)(MemberListContainer);
