import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import WorkIcon from '@material-ui/icons/Work';
import { useSelector } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& .MuiTypography-colorTextSecondary': {
      color: '#328fff !important',
      fontSize: 16,
    },
  },
  item: {
    padding: '12px 0 !important',
    position: 'relative',
    '& .MuiListItemText-primary': {
      fontSize: 18,
    },
    '& .MuiAvatar-root': {
      overflow: 'visible',
      width: 50,
      height: 50,
    },
  },
  skeletonRoot: {
    display: 'flex',
    padding: '12px 0 !important',
  },
  skeletonAvatar: {
    marginRight: theme.spacing(2),
  },
  badge: {
    fontSize: 18,
    position: 'absolute',
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ff1d1d',
    height: 30,
    width: 30,
    borderRadius: 30,
    color: '#fff',
    fontWeight: 'bold',
  },
}));

const GiveAwayWinner = () => {
  const classes = useStyles();
  const { data, winner1, winner2, winner3 } = useSelector(
    (state) => state.giveAway,
  );

  const [w1, setW1] = useState(null);
  const [w2, setW2] = useState(null);
  const [w3, setW3] = useState(null);

  useEffect(() => {
    if (winner1 !== null) {
      setW1(data.find((x) => x.tag === winner1));
    }
    if (winner2 !== null) {
      setW2(data.find((x) => x.tag === winner2));
    }
    if (winner3 !== null) {
      setW3(data.find((x) => x.tag === winner3));
    }
  }, [winner1, winner2, winner3]);

  const renderSkeleton = () => (
    <Box className={classes.skeletonRoot}>
      <Skeleton
        className={classes.skeletonAvatar}
        animation="wave"
        variant="circle"
        width={40}
        height={40}
      />
      <Skeleton variant="rect" width="75%" height={40} />
    </Box>
  );

  return (
    <List className={classes.root}>
      {winner1 !== null && w1 !== null ? (
        <ListItem className={classes.item}>
          <ListItemAvatar>
            <Avatar src={w1.league.iconUrls.medium} />
          </ListItemAvatar>
          <ListItemText primary={w1.name} secondary={'Rp. 100.000'} />
          <Box className={classes.badge}>
            <p>1</p>
          </Box>
        </ListItem>
      ) : (
        renderSkeleton()
      )}
      {winner2 !== null && w2 !== null ? (
        <ListItem className={classes.item}>
          <ListItemAvatar>
            <Avatar src={w2.league.iconUrls.medium} />
          </ListItemAvatar>
          <ListItemText primary={w2.name} secondary={'Rp. 100.000'} />
          <Box className={classes.badge}>
            <p>2</p>
          </Box>
        </ListItem>
      ) : (
        renderSkeleton()
      )}

      {winner3 !== null && w3 !== null ? (
        <ListItem className={classes.item}>
          <ListItemAvatar>
            <Avatar src={w3.league.iconUrls.medium} />
          </ListItemAvatar>
          <ListItemText primary={w3.name} secondary={'Rp. 100.000'} />
          <Box className={classes.badge}>
            <p>3</p>
          </Box>
        </ListItem>
      ) : (
        renderSkeleton()
      )}
    </List>
  );
};

export default GiveAwayWinner;
