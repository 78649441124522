export const townHallIcon = (townHallLevel) => {
  if (!townHallLevel) return;
  let startPath = '/statics/townhalls/';
  return startPath + 'Town_Hall' + townHallLevel + '.png';
};

export const playerTrophies = (points) => {
  if (!points) return;
  let startPath = '/statics/trophies/';
  switch (points) {
    case 1400:
      return startPath + 'vd4Lhz5b2I1P0cLH25B6q63JN3Wt1j2NTMhOYpMPQ4M.png';
    case 2000:
      return startPath + 'kSfTyNNVSvogX3dMvpFUTt72VW74w6vEsEFuuOV4osQ.png';
    case 1400:
      return startPath + 'vd4Lhz5b2I1P0cLH25B6q63JN3Wt1j2NTMhOYpMPQ4M.png';
    case 1400:
      return startPath + 'vd4Lhz5b2I1P0cLH25B6q63JN3Wt1j2NTMhOYpMPQ4M.png';
    case 1400:
      return startPath + 'vd4Lhz5b2I1P0cLH25B6q63JN3Wt1j2NTMhOYpMPQ4M.png';
    case 1400:
      return startPath + 'vd4Lhz5b2I1P0cLH25B6q63JN3Wt1j2NTMhOYpMPQ4M.png';
    case 1400:
      return startPath + 'vd4Lhz5b2I1P0cLH25B6q63JN3Wt1j2NTMhOYpMPQ4M.png';

    default:
      break;
  }
};

export const playerHeroes = (name) => {
  if (!name) return;
  let startPath = '/statics/heroes/';
  switch (name) {
    case 'Barbarian King':
      return startPath + 'Barbarian_King_info.png';
    case 'Archer Queen':
      return startPath + 'Archer_Queen_info.png';
    case 'Grand Warden':
      return startPath + 'Grand_Warden_info.png';
    case 'Royal Champion':
      return startPath + 'Royal_Champion_info.png';
    case 'Battle Machine':
      return startPath + 'Battle_Machine_info.png';
    default:
      return null;
  }
};

export const playerTroops = (name) => {
  if (!name) return;
  let startPath = '/statics/troops/';
  switch (name) {
    case 'Barbarian':
      return startPath;
    case 'Archer':
      return startPath;
    case 'Goblin':
      return startPath;
    case 'Giant':
      return startPath;
    case 'Wall Breaker':
      return startPath;
    case 'Balloon':
      return startPath;
    case 'Wizard':
      return startPath;
    case 'Healer':
      return startPath;
    case 'Dragon':
      return startPath;
    case 'P.E.K.K.A':
      return startPath;
    case 'Minion':
      return startPath;
    case 'Hog Rider':
      return startPath;
    case 'Valkyrie':
      return startPath;
    case 'Golem':
      return startPath;
    case 'Witch':
      return startPath;
    case 'Lava Hound':
      return startPath;
    case 'Bowler':
      return startPath;
    case 'Baby Dragon':
      return startPath;
    case 'Miner':
      return startPath;
    case 'Super Barbarian':
      return startPath;
    case 'Super Archer':
      return startPath;
    case 'Super Wall Breaker':
      return startPath;
    case 'Super Giant':
      return startPath;
    case 'Raged Barbarian':
      return startPath;
    case 'Sneaky Archer':
      return startPath;
    case 'Beta Minion':
      return startPath;
    case 'Boxer Giant':
      return startPath;
    case 'Bomber':
      return startPath;
    case 'Super P.E.K.K.A':
      return startPath;
    case 'Cannon Cart':
      return startPath;
    case 'Drop Ship':
      return startPath;
    case 'Baby Dragon':
      return startPath;
    case 'Night Witch':
      return startPath;
    case 'Wall Wrecker':
      return startPath;
    case 'Battle Blimp':
      return startPath;
    case 'Yeti':
      return startPath;
    case 'Sneaky Goblin':
      return startPath;
    case 'Rocket Balloon':
      return startPath;
    case 'Ice Golem':
      return startPath;
    case 'Electro Dragon':
      return startPath;
    case 'Stone Slammer':
      return startPath;
    case 'Inferno Dragon':
      return startPath;
    case 'Super Valkyrie':
      return startPath;
    case 'Dragon Rider':
      return startPath;
    case 'Super Witch':
      return startPath;
    case 'Hog Glider':
      return startPath;
    case 'Siege Barracks':
      return startPath;
    case 'Ice Hound':
      return startPath;
    case 'Headhunter':
      return startPath;
    case 'Super Wizard':
      return startPath;
    case 'Super Minion':
      return startPath;
    case 'Log Launcher':
      return startPath;
    default:
      return null;
  }
};
