import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Typography, makeStyles } from '@material-ui/core';
import { playerHeroes } from '../../../utils/player';

const PlayerHeroes = ({ heroes }) => {
  const classes = useStyles();
  const [column, setColumn] = useState(4);

  const createGrid = (count) => {
    switch (count) {
      case 3:
        setColumn(4);
      case 4:
        setColumn(4);
      default:
        setColumn(4);
    }
  };

  useEffect(() => {
    if (heroes) {
      createGrid(heroes.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heroes]);

  return (
    <Box pt={4} pb={6}>
      <Box textAlign="center" mb={3}>
        <Typography component="h4" variant="h5">
          Heroes
        </Typography>
      </Box>
      {Array.isArray(heroes) && heroes.length > 0 ? (
        <Grid
          container
          spacing={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {heroes.map((item, index) => (
            <Grid key={index} item xs={column} md={3}>
              <Box className={classes.boxHero} textAlign="center">
                <img src={playerHeroes(item.name)} alt={item.name} />
                <Typography component="p" variant="body2">
                  {item.name}
                </Typography>
                <Box className={classes.level}>
                  <Typography component="span" variant="subtitle2">
                    Level {item.level}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : null}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
  boxHero: {
    marginBottom: theme.spacing(4),
    width: '100%',
    '& img': {
      height: 72,
      width: 'auto',
    },
  },
  level: {
    backgroundColor: 'red',
    borderRadius: 20,
    margin: '0 auto',
    color: '#fbfbfb',
    width: 80,
  },
}));

PlayerHeroes.propTypes = {
  heroes: PropTypes.array,
};

export default PlayerHeroes;
