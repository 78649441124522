import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { townHallIcon } from '../../../utils/player';

const PlayerInfoHeader = ({ name, townHallLevel, trophies, tag, league }) => {
  const classes = useStyles();
  return (
    <Box textAlign="center" pt={4}>
      <Box className={classes.trophyIcon}>
        {league?.iconUrls?.medium ? (
          <img className={classes.icon} src={league?.iconUrls?.medium} />
        ) : (
          <img className={classes.icon} src={league?.iconUrls?.small} />
        )}
        {tag === '#82R9GRP9' && (
          <Box className={classes.owner}>
            <img
              src="https://riski.me/_next/image?url=%2F4yearsago.jpg&w=256&q=75"
              alt={name}
            />
          </Box>
        )}
      </Box>
      {/* <img
        className={classes.townHallImg}
        src={townHallIcon(townHallLevel)}
        alt={'Town hall ' + name}
      /> */}

      <Box mt={2}>
        <Typography component="h1" variant="h3">
          {name}
        </Typography>
      </Box>
      <Typography>{league?.name}</Typography>
      <Typography>{trophies}</Typography>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
  townHallImg: {
    width: 100,
    height: 'auto',
  },
  trophyIcon: {
    position: 'relative',
    width: 155,
    margin: '0 auto',
    '& img': {
      width: '100%',
      height: 'auto',
    },
  },
  owner: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 60,
    height: 60,
    borderRadius: 60,
    boxShadow: '0px 5px 29px 0px rgba(0,0,0,0.15)',
    border: '3px solid #fff',
    overflow: 'hidden',
    '& img': {
      width: '100%',
    },
  },
}));

PlayerInfoHeader.propTypes = {
  name: PropTypes.string,
  tag: PropTypes.string,
  townHallLevel: PropTypes.number,
  trophies: PropTypes.number,
  league: PropTypes.object,
};

export default PlayerInfoHeader;
