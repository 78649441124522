import { combineReducers } from 'redux';
import clan from './store/clan/reducer';
import player from './store/player/reducer';
import giveAway from './store/give-away/reducer';

export default combineReducers({
  clan,
  player,
  giveAway,
});
