import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Box, makeStyles } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { fetchPlayerInfo } from '../../store/player/actions';
import PageLoader from '../../components/page-loader';
import { useParams } from 'react-router';
import PlayerInfoHeader from '../../containers/player/player-info-header';
import PlayerHeroes from '../../containers/player/heroes';
import PlayerAchievements from '../../containers/player/player-achievements';
import PlayerTroops from '../../containers/player/troops';
import PlayerSpells from '../../containers/player/spells';

const PlayerDetail = ({ isFetching, playerData, fetchPlayerInfo }) => {
  const { playerTag } = useParams();
  const classes = useStyles();
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    if (playerTag) {
      fetchPlayerInfo(playerTag);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerTag]);

  useEffect(() => {
    if (playerData) {
      setPlayer(playerData[playerTag]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerData]);

  if (isFetching) return <PageLoader />;

  return (
    <Fragment>
      <Box
        className={classes.boxBgImage}
        style={{ backgroundImage: `url('/statics/782650.png')` }}
      ></Box>
      <Container className={classes.container}>
        <Box className={classes.rootBox}>
          <Box mt={3}>
            <PlayerInfoHeader
              tag={player?.tag}
              name={player?.name}
              trophies={player?.trophies}
              townHallLevel={player?.townHallLevel}
              league={player?.league}
            />
            <PlayerHeroes heroes={player?.heroes} />
            <PlayerTroops troops={player?.troops} />
            <PlayerSpells spells={player?.spells} />
            <PlayerAchievements achievements={player?.achievements} />
          </Box>
        </Box>
      </Container>
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  boxBgImage: {
    height: 240,
    [theme.breakpoints.up('sm')]: {
      height: 300,
    },
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center bottom',
  },
  container: {
    marginTop: -80,
    position: 'relative',
  },
  rootBox: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    minHeight: 300,
  },
}));

PlayerDetail.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  playerData: PropTypes.object.isRequired,
  fetchPlayerInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  playerData: state.player.data,
  isFetching: state.player.isLoading,
});

const mapDispatchToProps = {
  fetchPlayerInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayerDetail);
