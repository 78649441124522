import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { Box, makeStyles, Typography, Button } from '@material-ui/core';
// import GiveAwayMemberList from '../../containers/give-away/member-list';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { GIVE_AWAY_CODE } from '../../constants/give-away';
import ClanLogo from '../../components/logo';
import MarqueeSliderMemberList from '../../containers/give-away/marquee-slider-member-list';
import { __safePerformanceNow } from '../../utils';
import Reward from '../../containers/give-away/reward';

const GiveAwayPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { data } = useSelector((state) => state.giveAway);

  const [isValidLink, setIsValidLink] = useState(false);
  const { giveAwayId } = useParams();
  const t0 = useRef({});
  const [perfData, setPerfData] = useState({});

  const onReviewsStartPerformance = useCallback(
    () =>
      (t0.current = Object.assign({}, t0.current, {
        reviews: __safePerformanceNow(),
      })),
    [],
  );

  const onReviewsEndPerformance = useCallback(({ totalTries }) => {
    setPerfData((prevPerfData) =>
      Object.assign({}, prevPerfData, {
        reviews: {
          computationTime: Math.floor(
            __safePerformanceNow() - t0.current['reviews'],
          ),
          totalTries,
        },
      }),
    );
  }, []);

  useEffect(() => {
    if (giveAwayId === GIVE_AWAY_CODE) {
      setIsValidLink(true);
    } else {
      setIsValidLink(false);
    }

    window.scrollTo(0, 0);
  }, [giveAwayId]);

  return (
    <>
      <Helmet>
        <title>Giveaway - Typescript Clan</title>
        <meta name="theme-color" content="#9ffcc4" />
      </Helmet>

      {isValidLink ? (
        <Box className={classes.rootContainer}>
          <Box className={classes.root}>
            <Box onClick={() => navigate('/')}>
              <ClanLogo classes={classes.logo} />
            </Box>
            <Typography component="h1" variant="h2">
              Giveaway
            </Typography>
            <Typography component="h4" variant="h4">
              Typescript Clan
            </Typography>
            <Box mt={2}>
              <Typography component="p" variant="h5">
                Periode Desember 2021
              </Typography>
            </Box>
          </Box>

          <Box className={classes.memberTitle}>
            <Typography component="h5" variant="h5">
              Peserta :
            </Typography>
          </Box>
          <MarqueeSliderMemberList
            onStartPerformance={onReviewsStartPerformance}
            onEndPerformance={onReviewsEndPerformance}
            memberList={data}
          />

          <Reward />
          {/* <GiveAwayMemberList data={data} /> */}
        </Box>
      ) : (
        <Box className={classes.rootNotValid}>
          <Box className={classes.notValidTop}>
            <Typography component="h1" variant="h3">
              Give Away link is not valid.
            </Typography>

            <Typography component="p" variant="h5">
              Info lebih lanjut silahkan hubungi leader
            </Typography>
          </Box>

          <Button
            component={RouterLink}
            to="/"
            variant="contained"
            color="primary"
          >
            Kembali
          </Button>
        </Box>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(4),
    backgroundColor: '#9ffcc4',
  },
  rootContainer: {
    position: 'relative',
  },

  logo: {
    width: 120,
    heigth: 'auto',
  },
  memberTitle: { textAlign: 'center', marginBottom: theme.spacing(1) },
  rootNotValid: {
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  notValidTop: {
    marginBottom: theme.spacing(4),
  },
}));

export default GiveAwayPage;
