import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Box from '@material-ui/core/Box';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';

const PlayerTroops = ({ troops }) => {
  const classes = useStyles();

  return (
    <Box mb={6}>
      <Box textAlign="center" mb={2}>
        <Typography component="h4" variant="h5">
          Troops
        </Typography>
      </Box>
      <List className={classes.root}>
        {Array.isArray(troops) && troops.length > 0
          ? troops.map((item, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <SportsEsportsIcon />
                </ListItemIcon>
                <ListItemText primary={item.name} />
                <ListItemSecondaryAction>
                  <Box className={classes.level}>
                    <Typography component="span" variant="subtitle2">
                      Level: {item.level}
                    </Typography>
                  </Box>
                </ListItemSecondaryAction>
              </ListItem>
            ))
          : null}
      </List>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  listIcon: {
    color: theme.palette.secondary.main,
    backgroundColor: '#dde3ff !important',
  },
  level: {
    backgroundColor: '#357dff',
    borderRadius: 20,
    margin: '0 auto',
    color: '#fbfbfb',
    width: 80,
    textAlign: 'center',
  },
}));

PlayerTroops.propTypes = {
  troops: PropTypes.array,
};

export default PlayerTroops;
