import { createSelector } from 'reselect';

const root = (state) => state.clan;
export const clanInfoSelector = (state) => state.clan.data;
export const membersSelector = (state) => state.clan.data.memberList;

export const isFetchingClanInfoSelector = createSelector(
  root,
  (state) => state.isLoading,
);

export const clanLogoSelector = createSelector(clanInfoSelector, (state) =>
  state?.badgeUrls?.medium ? state?.badgeUrls?.medium : state?.badgeUrls?.small,
);

export const clanNameSelector = createSelector(
  clanInfoSelector,
  (state) => state?.name,
);

export const clanTagSelector = createSelector(
  clanInfoSelector,
  (state) => state?.tag,
);

export const clanAllMembersSelector = createSelector(
  membersSelector,
  (items) => items,
);

export const clanLeadersSelector = createSelector(membersSelector, (items) =>
  items.filter((item) => item.role === 'leader'),
);
export const clanCoLeadersSelector = createSelector(membersSelector, (items) =>
  items.filter((item) => item.role === 'coLeader'),
);
export const clanSeniorsSelector = createSelector(membersSelector, (items) =>
  items.filter((item) => item.role === 'admin'),
);
export const clanMembersSelector = createSelector(membersSelector, (items) =>
  items.filter((item) => item.role === 'member'),
);
