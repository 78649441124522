import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { WhatsApp } from '@material-ui/icons';
import GiveAwayWinner from './winner';

const Reward = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box mb={2} className={classes.intro}>
        <Typography>
          Sebelumnya gw mau berterima kasih kepada temen2 yang setia di clan
          Typescript yang telah membangun clan bersama-sama.
        </Typography>
        <Typography>
          Terima kasih juga kenapa bapak Komandan Jeremy yang sudah memimpin
          medan perang 😁. Mohon maaf ya pak saya sibuk sangat sampai2 jarang on
        </Typography>
        <Typography>
          Kali ini gw mau berbagi sedikit rezeki buat temen2 untuk periode bulan
          Desember 2021 ini
        </Typography>
      </Box>

      <Box className={classes.boxReward}>
        <Typography component="h3" variant="h5">
          Hadiah
        </Typography>
        <Typography component="h1" variant="h2" className={classes.textReward}>
          Rp. 300.000
        </Typography>
        <Typography>Untuk 3 orang terpilih</Typography>
        <Typography>1 orang (Rp. 100.000)</Typography>
      </Box>

      <Box mt={3}>
        <Typography>
          Maaf, belum bisa ngasih gede ☹️. Insya allah bulan depan bakalan ada
          event Giveaway lagi yang mungkin bisa lebih dari ini 😊
        </Typography>
      </Box>

      <Box className={classes.winner} mt={6}>
        <Typography component="h3" variant="h3">
          Pemenang 🎉🎉🎉
        </Typography>
      </Box>
      <Box mt={3} mb={1} className={classes.winnerBox}>
        <GiveAwayWinner />
      </Box>
      <Typography variant="subtitle2">
        Jika pemenang belum muncul proses acak belum selesai.
      </Typography>
      <Box mt={2}>
        <Typography variant="body2">
          Selamat kepada pemenang di atas. Mohon segera memberikan no rek ke
          leader
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="body2">
          Yang belum menang tolong jangan berkecil hati ya, masih ada periode
          bulan depan 😁
        </Typography>
      </Box>

      <Box className={classes.faq} mt={6} textAlign="left">
        <Typography component="h2" variant="h3">
          FAQ :
        </Typography>

        <Box>
          <Typography>Syaratnya apaan tuh lead?</Typography>
          <Typography component="h3" variant="h5">
            Peserta harus member clan Typescript
          </Typography>
          <Typography component="h3" variant="h5">
            Apabila proses Giveaway ini sedang berlangsung lalu pemenang keluar
            dari clan maka hadiah akan diberikan ke peserta lain.
          </Typography>
        </Box>
        <Box>
          <Typography> Sistem pemilihannya gimana tuh ?</Typography>
          <Typography component="h3" variant="h5">
            Untuk sistem pemilihan gw pake randomize, jadi bukan gw yang
            menentukan siapa 3 orang yang akan menang, tapi secara acak.
          </Typography>
        </Box>
        <Box>
          <Typography>Hadiahnya di transfer lewat apa ?</Typography>
          <Typography component="h3" variant="h5">
            Untuk transfer hadiahnya kalian bisa request sendiri tapi diutamakan
            Paypal, Bank BCA, Mandiri, BRI, ShopeePay, Dana, atau GoPay
          </Typography>
        </Box>
        <Box>
          <Typography>Kapan akan diumumkan pemenangnya ?</Typography>
          <Typography component="h3" variant="h5">
            Untuk 3 orang pemenang akan di umumkan di website ini
            selambat-lambatnya besok siang atau sore. Lah kenapa nggak sekarang
            aja ? karena mungkin akan ada peserta yang ditambahkan jadi gw perlu
            konfirmasi dulu ke temen2 yg lain siapa aja yg akan ikut.
          </Typography>
        </Box>
        <Box>
          <Typography>Penerimaan hadiah</Typography>
          <Typography component="h3" variant="h5">
            Untuk ke 3 orang pemenang akan gw japri (bagi yang join di group wa)
            yang nggak join chat nomor rekening di coc aja.
          </Typography>
        </Box>
        <Box>
          <Typography>Other </Typography>
          <Typography component="h3" variant="h5">
            Pemenang selambat-lambatnya harus memberikan no rekening / Nomor
            GoPay, ShopeePay atau Dana 1 minggu setelah diumumkan pemenangnya.
            Kalau tidak ya gw bingung mau transfer ke mana
          </Typography>
        </Box>
      </Box>

      <Box className={classes.info} mt={6}>
        <Typography component="h3" variant="h4">
          Ada yang mau ditanyakan ?
        </Typography>
        <Typography>Hubungi gw pake tombol dibawah aja biar cepet.</Typography>
        <Box mt={1}>
          <WhatsappButton
            component="a"
            target="_blank"
            href={`https://api.whatsapp.com/send?phone=628888235344&text=Halo%2C%20lead`}
            className={classes.btnCta}
            startIcon={<WhatsApp />}
          >
            Whatsapp
          </WhatsappButton>
        </Box>
      </Box>

      <Box mt={6}>
        <Typography className={classes.notes} variant="subtitle2">
          Ketentuan, Peserta & Hadiah dapat berubah sewaktu-waktu yang akan di
          infokan ke temen2 di group atau chat coc.
        </Typography>
      </Box>
    </Box>
  );
};

const WhatsappButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 19,
    padding: '6px 22px',
    border: '1px solid',
    borderRadius: 24,
    lineHeight: 1.5,
    backgroundColor: '#00b36b',
    borderColor: '#00b36b',
    color: '#fbfbfb',
    '&:hover': {
      backgroundColor: '#00995c',
      borderColor: '#00995c',
      boxShadow: 'none',
    },
  },
})(Button);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(0, 2),
  },
  intro: {
    '& p': {
      marginBottom: theme.spacing(1),
    },
  },
  faq: {
    '& .MuiBox-root': {
      marginBottom: theme.spacing(1.85),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: '#d4ecff',
      padding: theme.spacing(1, 1.4),
    },
    '& span': {
      fontSize: 14,
      fontWeight: 'normal',
      display: 'inline-block',
    },
    '& h3': {
      fontSize: 16,
      fontWeight: 'bold',
      display: 'inline-block',
    },
  },
  textReward: {
    color: '#f8eb74',
  },
  winner: {},
  notes: {
    color: 'red',
  },
  boxReward: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: theme.shape.borderRadius * 3,
    padding: theme.spacing(2, 3),
    width: '80%',
  },
  winnerBox: {
    backgroundColor: '#ffebb3',
    borderRadius: theme.shape.borderRadius * 3,
    padding: theme.spacing(2, 3),
    width: '94%',
  },
}));

export default Reward;
