import * as ActionsTypes from './constants';

const initialState = {
  data: {},
  isError: false,
  isLoading: false,
};

export default function playerReducer(state = initialState, action) {
  switch (action.type) {
    case ActionsTypes.FETCHING_PLAYER_INFO_REQUEST:
      return {
        ...state,
        isError: false,
        isLoading: true,
      };
    case ActionsTypes.FETCHING_PLAYER_INFO_FAILURE:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };
    case ActionsTypes.FETCHING_PLAYER_INFO_SUCCESS:
      return {
        ...state,
        isError: false,
        isLoading: false,
        data: {
          ...state.data,
          [action.payload.playerTag]: action.payload.data,
        },
      };
    default:
      return state;
  }
}
