import { createTheme } from '@material-ui/core';

import breakpoints from './breakpoint';
import palette from './palette';
import shadows from './shadows';
import typography from './typography';
import custom from './custom';

export const theme = createTheme({
  breakpoints,
  palette,
  shadows,
  typography,
  custom,
});
