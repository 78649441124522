import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import StarIcon from '@material-ui/icons/Star';

const PlayerAchievements = ({ achievements }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Box textAlign="center" mb={2}>
        <Typography component="h4" variant="h5">
          Achievements
        </Typography>
      </Box>
      <List className={classes.root}>
        {Array.isArray(achievements) && achievements.length > 0
          ? achievements.map((item, index) => (
              <ListItem key={index}>
                <ListItemAvatar>
                  <Box className={classes.star}>
                    {item.stars === 3 && (
                      <Fragment>
                        <StarIcon />
                        <StarIcon />
                        <StarIcon />
                      </Fragment>
                    )}
                    {item.stars === 2 && (
                      <Fragment>
                        <StarIcon />
                        <StarIcon />
                      </Fragment>
                    )}
                    {item.stars === 1 && <StarIcon />}
                  </Box>
                  <Avatar className={classes.listIcon}>
                    <CheckIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={item.name} secondary={item.info} />
              </ListItem>
            ))
          : null}
      </List>
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  listIcon: {
    color: theme.palette.secondary.main,
    backgroundColor: '#dde3ff !important',
  },
  star: {
    zIndex: 1,
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    '& svg': {
      fontSize: 13,
      color: '#ff9800',
    },
  },
}));

PlayerAchievements.propTypes = {
  achievements: PropTypes.array,
};

export default PlayerAchievements;
