import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import ClanLogo from '../../components/logo';
import {
  Box,
  Button,
  Typography,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import { WhatsApp } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';

const HomeContainer = ({ clanInfo, setOpenDialog }) => {
  const classes = useStyles();
  return (
    <Fragment>
      <Box
        display="flex"
        alingItems="center"
        flexDirection="column"
        justifyContent="center"
        className={classes.root}
      >
        <Box textAlign="center" mb={2}>
          <Box>
            <ClanLogo classes={classes.logo} />
          </Box>
          <Box>
            <Typography component="p">Welcome to clan</Typography>
          </Box>
          <Typography component="h1" variant="h1">
            {clanInfo?.name}
          </Typography>
          <Box
            mt={1}
            display="flex"
            alingItems="center"
            justifyContent="center"
          >
            <Box className={classes.clanTag}>
              <Typography component="span">{clanInfo?.tag}</Typography>
            </Box>
          </Box>
        </Box>
        <Box textAlign="center" className={classes.descriptionContainer}>
          <Typography
            className={classes.description}
            component="p"
            variant="subtitle2"
          >
            {clanInfo?.description}
          </Typography>
        </Box>

        <Box mt={2} display="flex" alignItems="center" justifyContent="center">
          <Box className={clsx(classes.country, classes.badgeItem)}>
            <img src={'/statics/id.png'} alt="id" />
            <Typography component="span" variant="subtitle2">
              Indonesia
            </Typography>
          </Box>
          <Box className={clsx(classes.status, classes.badgeItem)}>
            <Typography component="span" variant="subtitle2">
              Status: {clanInfo?.type}
            </Typography>
          </Box>
          <Box className={clsx(classes.members, classes.badgeItem)}>
            <Typography component="span" variant="subtitle2">
              Members: {clanInfo?.members}
            </Typography>
          </Box>
          <Box className={clsx(classes.level, classes.badgeItem)}>
            <Typography component="span" variant="subtitle2">
              Level: {clanInfo?.clanLevel}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.ctaHome} textAlign="center">
        <WhatsappButton
          onClick={() => setOpenDialog(true)}
          className={classes.btnCta}
          startIcon={<WhatsApp />}
        >
          Join With Us
        </WhatsappButton>
        <GiveawayButton
          component={RouterLink}
          to="/g1"
          className={classes.btnCta}
          startIcon={<FlashOnIcon />}
        >
          Giveaway
        </GiveawayButton>
      </Box>
    </Fragment>
  );
};

const WhatsappButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 17,
    padding: '10px 22px',
    border: '1px solid',
    borderRadius: 24,
    lineHeight: 1.5,
    backgroundColor: '#00b36b',
    borderColor: '#00b36b',
    color: '#fbfbfb',
    '&:hover': {
      backgroundColor: '#008550',
      borderColor: '#008550',
      boxShadow: 'none',
    },
  },
})(Button);

const GiveawayButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 17,
    padding: '10px 22px',
    border: '1px solid',
    borderRadius: 24,
    lineHeight: 1.5,
    backgroundColor: '#0098fd',
    borderColor: '#0098fd',
    color: '#fbfbfb',
    marginLeft: 8,
    '&:hover': {
      backgroundColor: '#008550',
      borderColor: '#008550',
      boxShadow: 'none',
    },
  },
})(Button);

const useStyles = makeStyles((theme) => ({
  root: {
    height: 540,
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.up('sm')]: {
      height: 520,
    },
  },
  logo: {
    width: 165,
    height: 165,
  },
  descriptionContainer: {
    width: '100%',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('sm')]: {
      width: 460,
      margin: '0 auto',
    },
  },
  description: {
    lineHeight: 1.75,
  },
  ctaHome: {
    marginTop: -22,
  },
  btnCta: {
    boxShadow: '0px 5px 29px 0px rgba(0,0,0,0.15)',
  },
  badgeItem: {
    border: '1px solid #fff',
    borderRadius: 25,
    padding: theme.spacing(0.4, 1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(0, 0.5),
  },
  country: {
    '& img': {
      width: 22,
      height: 'auto',
      marginRight: theme.spacing(0.6),
    },
  },
  clanTag: {
    borderRadius: theme.shape.borderRadius * 5,
    padding: theme.spacing(0.8, 2),
    border: '1px solid #fff',
    backgroundColor: theme.palette.primary.main,
  },
}));

HomeContainer.propTypes = {
  clanInfo: PropTypes.object.isRequired,
  setOpenDialog: PropTypes.func,
};

export default HomeContainer;
