export default {
  keys: ['xs', 'sm', 'md', 'lg', 'xl'],
  values: {
    xs: 0,
    sm: 540,
    md: 960,
    lg: 1100,
    xl: 1920,
  },
};
