import * as ActionTypes from './constants';
import PlayerService from './service';

/**
 * --------------------
 * Fetch player info.
 * --------------------
 */
export const fetchPlayerInfo = (playerTag) => {
  // player tag without #
  return async (dispatch) => {
    dispatch(fetchingPlayerRequest());
    try {
      const response = await PlayerService.getPlayer(playerTag);
      if (response.status === 200) {
        dispatch(fetchingPlayerSuccess({ playerTag, data: response.data }));
      }
    } catch (e) {
      dispatch(fetchingPlayerFailure());
    }
  };
};

const fetchingPlayerRequest = () => ({
  type: ActionTypes.FETCHING_PLAYER_INFO_REQUEST,
});

const fetchingPlayerFailure = () => ({
  type: ActionTypes.FETCHING_PLAYER_INFO_FAILURE,
});

const fetchingPlayerSuccess = ({ playerTag, data }) => ({
  type: ActionTypes.FETCHING_PLAYER_INFO_SUCCESS,
  payload: { playerTag, data },
});
