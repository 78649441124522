export const GIVE_AWAY_PIN = process.env.REACT_APP_PIN_GIVE_AWAY;
export const GIVE_AWAY_CODE =
  process.env.REACT_APP_CURRENT_GIVE_AWAY_ACTIVE_CODE;

export const GIVE_AWAY_MEMBER = [
  {
    tag: '#22P20LRJ0',
    name: '♧Siantar_Punya♤',
    role: 'coLeader',
    expLevel: 225,
    league: {
      id: 29000014,
      name: 'Master League II',
      iconUrls: {
        small:
          'https://api-assets.clashofclans.com/leagues/72/4wtS1stWZQ-1VJ5HaCuDPfdhTWjeZs_jPar_YPzK6Lg.png',
        tiny: 'https://api-assets.clashofclans.com/leagues/36/4wtS1stWZQ-1VJ5HaCuDPfdhTWjeZs_jPar_YPzK6Lg.png',
        medium:
          'https://api-assets.clashofclans.com/leagues/288/4wtS1stWZQ-1VJ5HaCuDPfdhTWjeZs_jPar_YPzK6Lg.png',
      },
    },
    trophies: 2872,
    versusTrophies: 4644,
    clanRank: 7,
    previousClanRank: 7,
    donations: 3600,
    donationsReceived: 1233,
  },
  {
    tag: '#YGPVPPQR',
    name: 'Evil gonna die',
    role: 'admin',
    expLevel: 168,
    league: {
      id: 29000014,
      name: 'Master League II',
      iconUrls: {
        small:
          'https://api-assets.clashofclans.com/leagues/72/4wtS1stWZQ-1VJ5HaCuDPfdhTWjeZs_jPar_YPzK6Lg.png',
        tiny: 'https://api-assets.clashofclans.com/leagues/36/4wtS1stWZQ-1VJ5HaCuDPfdhTWjeZs_jPar_YPzK6Lg.png',
        medium:
          'https://api-assets.clashofclans.com/leagues/288/4wtS1stWZQ-1VJ5HaCuDPfdhTWjeZs_jPar_YPzK6Lg.png',
      },
    },
    trophies: 2930,
    versusTrophies: 2326,
    clanRank: 5,
    previousClanRank: 5,
    donations: 124,
    donationsReceived: 1076,
  },
  {
    tag: '#QPQ2CG8UL',
    name: '°BABYRA°',
    role: 'admin',
    expLevel: 82,
    league: {
      id: 29000013,
      name: 'Master League III',
      iconUrls: {
        small:
          'https://api-assets.clashofclans.com/leagues/72/pSXfKvBKSgtvfOY3xKkgFaRQi0WcE28s3X35ywbIluY.png',
        tiny: 'https://api-assets.clashofclans.com/leagues/36/pSXfKvBKSgtvfOY3xKkgFaRQi0WcE28s3X35ywbIluY.png',
        medium:
          'https://api-assets.clashofclans.com/leagues/288/pSXfKvBKSgtvfOY3xKkgFaRQi0WcE28s3X35ywbIluY.png',
      },
    },
    trophies: 2584,
    versusTrophies: 2148,
    clanRank: 9,
    previousClanRank: 10,
    donations: 620,
    donationsReceived: 1090,
  },

  {
    tag: '#2JLQ28UVU',
    name: 'mahatirMd',
    role: 'admin',
    expLevel: 146,
    league: {
      id: 29000011,
      name: 'Crystal League II',
      iconUrls: {
        small:
          'https://api-assets.clashofclans.com/leagues/72/jhP36EhAA9n1ADafdQtCP-ztEAQjoRpY7cT8sU7SW8A.png',
        tiny: 'https://api-assets.clashofclans.com/leagues/36/jhP36EhAA9n1ADafdQtCP-ztEAQjoRpY7cT8sU7SW8A.png',
        medium:
          'https://api-assets.clashofclans.com/leagues/288/jhP36EhAA9n1ADafdQtCP-ztEAQjoRpY7cT8sU7SW8A.png',
      },
    },
    trophies: 2296,
    versusTrophies: 1078,
    clanRank: 16,
    previousClanRank: 17,
    donations: 533,
    donationsReceived: 169,
  },
  {
    tag: '#Q2CP00JVG',
    name: 'WALK™',
    role: 'admin',
    expLevel: 76,
    league: {
      id: 29000009,
      name: 'Gold League I',
      iconUrls: {
        small:
          'https://api-assets.clashofclans.com/leagues/72/CorhMY9ZmQvqXTZ4VYVuUgPNGSHsO0cEXEL5WYRmB2Y.png',
        tiny: 'https://api-assets.clashofclans.com/leagues/36/CorhMY9ZmQvqXTZ4VYVuUgPNGSHsO0cEXEL5WYRmB2Y.png',
        medium:
          'https://api-assets.clashofclans.com/leagues/288/CorhMY9ZmQvqXTZ4VYVuUgPNGSHsO0cEXEL5WYRmB2Y.png',
      },
    },
    trophies: 1813,
    versusTrophies: 1809,
    clanRank: 25,
    previousClanRank: 25,
    donations: 227,
    donationsReceived: 32,
  },
  {
    tag: '#QQQP0Y8PL',
    name: 'Breath_Dragon07',
    role: 'admin',
    expLevel: 43,
    league: {
      id: 29000009,
      name: 'Gold League I',
      iconUrls: {
        small:
          'https://api-assets.clashofclans.com/leagues/72/CorhMY9ZmQvqXTZ4VYVuUgPNGSHsO0cEXEL5WYRmB2Y.png',
        tiny: 'https://api-assets.clashofclans.com/leagues/36/CorhMY9ZmQvqXTZ4VYVuUgPNGSHsO0cEXEL5WYRmB2Y.png',
        medium:
          'https://api-assets.clashofclans.com/leagues/288/CorhMY9ZmQvqXTZ4VYVuUgPNGSHsO0cEXEL5WYRmB2Y.png',
      },
    },
    trophies: 1771,
    versusTrophies: 1399,
    clanRank: 27,
    previousClanRank: 27,
    donations: 35,
    donationsReceived: 514,
  },
  {
    tag: '#PPPG8VPUU',
    name: 'X DIL',
    role: 'admin',
    expLevel: 133,
    league: {
      id: 29000007,
      name: 'Gold League III',
      iconUrls: {
        small:
          'https://api-assets.clashofclans.com/leagues/72/vd4Lhz5b2I1P0cLH25B6q63JN3Wt1j2NTMhOYpMPQ4M.png',
        tiny: 'https://api-assets.clashofclans.com/leagues/36/vd4Lhz5b2I1P0cLH25B6q63JN3Wt1j2NTMhOYpMPQ4M.png',
        medium:
          'https://api-assets.clashofclans.com/leagues/288/vd4Lhz5b2I1P0cLH25B6q63JN3Wt1j2NTMhOYpMPQ4M.png',
      },
    },
    trophies: 1567,
    versusTrophies: 3135,
    clanRank: 34,
    previousClanRank: 24,
    donations: 337,
    donationsReceived: 638,
  },
  {
    tag: '#QQQ000R0P',
    name: 'Dello',
    role: 'admin',
    expLevel: 66,
    league: {
      id: 29000018,
      name: 'Champion League I',
      iconUrls: {
        small:
          'https://api-assets.clashofclans.com/leagues/72/9v_04LHmd1LWq7IoY45dAdGhrBkrc2ZFMZVhe23PdCE.png',
        tiny: 'https://api-assets.clashofclans.com/leagues/36/9v_04LHmd1LWq7IoY45dAdGhrBkrc2ZFMZVhe23PdCE.png',
        medium:
          'https://api-assets.clashofclans.com/leagues/288/9v_04LHmd1LWq7IoY45dAdGhrBkrc2ZFMZVhe23PdCE.png',
      },
    },
    trophies: 3962,
    versusTrophies: 1826,
    clanRank: 3,
    previousClanRank: 3,
    donations: 149,
    donationsReceived: 1368,
  },
  {
    tag: '#20UY2VU2Y',
    name: 'KsH.Vyan1styあざと',
    role: 'admin',
    expLevel: 167,
    league: {
      id: 29000006,
      name: 'Silver League I',
      iconUrls: {
        small:
          'https://api-assets.clashofclans.com/leagues/72/nvrBLvCK10elRHmD1g9w5UU1flDRMhYAojMB2UbYfPs.png',
        tiny: 'https://api-assets.clashofclans.com/leagues/36/nvrBLvCK10elRHmD1g9w5UU1flDRMhYAojMB2UbYfPs.png',
        medium:
          'https://api-assets.clashofclans.com/leagues/288/nvrBLvCK10elRHmD1g9w5UU1flDRMhYAojMB2UbYfPs.png',
      },
    },
    trophies: 1339,
    versusTrophies: 2398,
    clanRank: 38,
    previousClanRank: 38,
    donations: 8305,
    donationsReceived: 333,
  },
  {
    tag: '#LJUG0JR0R',
    name: 'Adityans',
    role: 'admin',
    expLevel: 56,
    league: {
      id: 29000006,
      name: 'Silver League I',
      iconUrls: {
        small:
          'https://api-assets.clashofclans.com/leagues/72/nvrBLvCK10elRHmD1g9w5UU1flDRMhYAojMB2UbYfPs.png',
        tiny: 'https://api-assets.clashofclans.com/leagues/36/nvrBLvCK10elRHmD1g9w5UU1flDRMhYAojMB2UbYfPs.png',
        medium:
          'https://api-assets.clashofclans.com/leagues/288/nvrBLvCK10elRHmD1g9w5UU1flDRMhYAojMB2UbYfPs.png',
      },
    },
    trophies: 1302,
    versusTrophies: 1267,
    clanRank: 39,
    previousClanRank: 41,
    donations: 0,
    donationsReceived: 569,
  },
  {
    tag: '#208Q98R00',
    name: 'yakub ONCE',
    role: 'admin',
    expLevel: 114,
    league: {
      id: 29000008,
      name: 'Gold League II',
      iconUrls: {
        small:
          'https://api-assets.clashofclans.com/leagues/72/Y6CveuHmPM_oiOic2Yet0rYL9AFRYW0WA0u2e44-YbM.png',
        tiny: 'https://api-assets.clashofclans.com/leagues/36/Y6CveuHmPM_oiOic2Yet0rYL9AFRYW0WA0u2e44-YbM.png',
        medium:
          'https://api-assets.clashofclans.com/leagues/288/Y6CveuHmPM_oiOic2Yet0rYL9AFRYW0WA0u2e44-YbM.png',
      },
    },
    trophies: 1660,
    versusTrophies: 1841,
    clanRank: 28,
    previousClanRank: 28,
    donations: 1500,
    donationsReceived: 1373,
  },
  {
    tag: '#QLRLR9P9',
    name: 'jhon',
    role: 'admin',
    expLevel: 166,
    league: {
      id: 29000018,
      name: 'Champion League I',
      iconUrls: {
        small:
          'https://api-assets.clashofclans.com/leagues/72/9v_04LHmd1LWq7IoY45dAdGhrBkrc2ZFMZVhe23PdCE.png',
        tiny: 'https://api-assets.clashofclans.com/leagues/36/9v_04LHmd1LWq7IoY45dAdGhrBkrc2ZFMZVhe23PdCE.png',
        medium:
          'https://api-assets.clashofclans.com/leagues/288/9v_04LHmd1LWq7IoY45dAdGhrBkrc2ZFMZVhe23PdCE.png',
      },
    },
    trophies: 3894,
    versusTrophies: 3700,
    clanRank: 2,
    previousClanRank: 2,
    donations: 827,
    donationsReceived: 787,
  },
  {
    tag: '#YLRYQCV8',
    name: 'VTB•"KENTIR"',
    role: 'admin',
    expLevel: 123,
    league: {
      id: 29000012,
      name: 'Crystal League I',
      iconUrls: {
        small:
          'https://api-assets.clashofclans.com/leagues/72/kSfTyNNVSvogX3dMvpFUTt72VW74w6vEsEFuuOV4osQ.png',
        tiny: 'https://api-assets.clashofclans.com/leagues/36/kSfTyNNVSvogX3dMvpFUTt72VW74w6vEsEFuuOV4osQ.png',
        medium:
          'https://api-assets.clashofclans.com/leagues/288/kSfTyNNVSvogX3dMvpFUTt72VW74w6vEsEFuuOV4osQ.png',
      },
    },
    trophies: 2419,
    versusTrophies: 2135,
    clanRank: 10,
    previousClanRank: 10,
    donations: 288,
    donationsReceived: 402,
  },
];
