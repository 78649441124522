import { colors } from '@material-ui/core';

export default {
  background: {
    dark: '#F4F6F8',
    default: '#fbfbfb',
    paper: colors.common.white,
  },
  primary: {
    light: '#D6E4FF',
    main: '#3366FF',
    dark: '#1939B7',
    contrastText: '#fbfbfb',
  },
  secondary: {
    light: '#FFF2D0',
    main: '#FF9914',
    dark: '#DB790E',
    contrastText: '#fff',
  },
  green: {
    light: '#51ef89',
    main: '#00b13f',
    dark: '#008630',
    contrastText: '#fff',
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
  },
};
