import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import ReactWhatsapp from 'react-whatsapp';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from '@material-ui/core';

const DialogRequestJoin = ({ openDialog, setOpenDialog }) => {
  const classes = useStyles();
  const handleClose = () => {
    setOpenDialog(false);
  };

  const [playerTag, setPlayerTag] = useState('');
  const [enableSubmit, setEnableSubmit] = useState('');

  useEffect(() => {
    if (playerTag.length >= 7) {
      setEnableSubmit(true);
    } else {
      setEnableSubmit(false);
    }
  }, [playerTag]);

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <Box mt={1}>
          <Typography component="h2" variant="h4">
            Request Join
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Box mb={3}>
            Masukan ID/Player Tag kamu untuk bergabung dengan
            <strong> Clan Typescript</strong>
          </Box>
        </DialogContentText>
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-adornment-player-tag">
            Player Tag
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-player-tag"
            value={playerTag}
            onChange={(e) => setPlayerTag(e.target.value)}
            startAdornment={<InputAdornment position="start">#</InputAdornment>}
            labelWidth={100}
          />
        </FormControl>
        <Box mt={1}>
          <Typography variant="subtitle2">
            Untuk mempermudah pencarian kami hanya menerima id pemain (Bukan
            Nickname)
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Batal
        </Button>
        {enableSubmit ? (
          <Button
            component="a"
            target="_blank"
            href={`https://api.whatsapp.com/send?phone=628888235344&text=Halo%2C%20saya%20ingin%20bergabung%20dengan%20Typescript%0A%0AIni%20id%20saya%20*%23${playerTag}*`}
          >
            Ya, saya mau bergabung
          </Button>
        ) : (
          <Button disabled color="primary">
            Ya, saya mau bergabung
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {},
}));

DialogRequestJoin.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
};

export default DialogRequestJoin;
