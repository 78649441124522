import React, { Fragment } from 'react';
import Box from '@material-ui/core/Box';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import FooterContainer from '../../containers/footer';

const MainLayout = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <div className={classes.root}>
        <Box display="flex" flex="1" flexDirection="column" component="main">
          <Outlet />
        </Box>
        <FooterContainer />
      </div>
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.default,
    backgroundColor: '#fbfbfb',
    minHeight: '100vh',
    overflow: 'hidden',
    width: '100%',
  },
}));

export default MainLayout;
