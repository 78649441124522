import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Marquee from 'react-marquee-slider';
import styled from 'styled-components';
import times from 'lodash/times';
import { withSize } from 'react-sizeme';
import { nanoid } from 'nanoid';

const Height = styled.div`
  position: relative;
  width: 100%;
  height: 160px;
`;

const Box = styled.div`
  padding: ${(props) => props.scale * 10}px;
`;

const Review = styled.div`
  width: ${(props) => props.scale * 250}px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: ${(props) => props.scale * 10}px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.15);
  height: 100px;
`;

const Avatar = styled.div`
  width: ${(props) => props.scale * 58}px;
  height: ${(props) => props.scale * 58}px;
  overflow: hidden;
  flex-shrink: 0;
  margin-right: ${(props) => props.scale * 15}px;
  img {
    max-width: 100%;
  }
`;

const Content = styled.div`
  * {
    margin: 0;
    color: #000;
    line-height: ${(props) => props.scale * 20}px;
    text-align: left;
    font-weight: bold;
  }
  p {
    font-size: ${(props) => props.scale * 16}px;
  }
  h3 {
    font-size: ${(props) => props.scale * 18}px;
    margi-bottom: 0.4rem;
    color: #3366ff;
  }
`;

const MarqueeSliderMemberList = ({
  size,
  onStartPerformance,
  onEndPerformance,
  memberList,
}) => {
  const [key, setKey] = useState(nanoid());

  useEffect(() => {
    setKey(nanoid());
  }, [size, setKey]);

  let scale = 0.65;

  if (size && size.width > 800) {
    scale = 0.75;
  }

  if (size && size.width > 1100) {
    scale = 0.9;
  }

  if (size && size.width > 1400) {
    scale = 1;
  }

  return (
    <Height>
      <Marquee
        key={key}
        velocity={25}
        // scatterRandomly
        minScale={0.5}
        onInit={onStartPerformance}
        onFinish={onEndPerformance}
      >
        {memberList.map((member) => {
          let _image = member.league.iconUrls.medium;
          return (
            <Box key={`member-id-${member.tag}`} scale={0.9}>
              <Review scale={0.9}>
                <Avatar scale={0.9}>
                  <img src={_image} alt="" />
                </Avatar>
                <Content scale={0.9}>
                  <h3>{member.tag}</h3>
                  <p>{member.name}</p>
                </Content>
              </Review>
            </Box>
          );
        })}
      </Marquee>
    </Height>
  );
};

MarqueeSliderMemberList.propTypes = {
  size: PropTypes.any,
  onStartPerformance: PropTypes.any,
  onEndPerformance: PropTypes.any,
  memberList: PropTypes.array,
};

// will end in a loop without React.memo
export default React.memo(withSize()(MarqueeSliderMemberList));
