import React from 'react';
import HomePage from '../pages/home';
import PlayerDetail from '../pages/player-detail';
import NotMatchPage from '../pages/errors/404';
import MainLayout from '../layouts/main-layout';
import GiveAwayPage from '../pages/give-away';

const routes = (/* somethingArgWhatYouWantToPass */) => [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: '/player/:playerTag',
        element: <PlayerDetail />,
      },
      {
        path: '/',
        element: <HomePage />,
      },
      {
        path: '/:giveAwayId',
        element: <GiveAwayPage />,
      },
      { path: '*', element: <NotMatchPage /> },
    ],
  },
];

export default routes;
