import { GIVE_AWAY_MEMBER } from '../../constants/give-away';
import * as ActionsTypes from './constants';

const initialState = {
  data: GIVE_AWAY_MEMBER,
  winner1: '#20UY2VU2Y',
  winner2: '#QQQ000R0P',
  winner3: '#QPQ2CG8UL',
};

export default function giveAwayReducer(state = initialState, action) {
  switch (action.type) {
    case ActionsTypes.SET_GIVE_AWAY_WINNER_1:
      return {
        ...state,
        winner1: action.payload,
      };
    case ActionsTypes.SET_GIVE_AWAY_WINNER_2:
      return {
        ...state,
        winner2: action.payload,
      };
    case ActionsTypes.SET_GIVE_AWAY_WINNER_3:
      return {
        ...state,
        winner3: action.payload,
      };
    default:
      return state;
  }
}
